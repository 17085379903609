import { makeObservable } from 'mobx';
import {
  expr,
} from 'mobx-utils';
import {
  BehaviorSubject,
  Subject,
} from 'rxjs';

import type { IotDeviceIdT } from '^/__generated__/pothosZod/generated_scalars';
import { type FreshnessType, getCurrentFreshness, asSuccessPromise } from '^/types/__ResultType';
import { extractTokenData } from '^/utils/token/forClient';

import type { APIClient } from '../apiClient';
import { createAutoSyncedCaller3 } from '../autoSyncedCore/autoSyncedCaller3';
import { refreshingAPICall$ } from '../autoSyncedCore/autoSyncedPromiseAPICaller';
import type { TokenRepo } from '../repositories/TokenRepo';

export class IotDevicesListCore {
  constructor(
    public apiClient: APIClient,
    public tokenRepo: TokenRepo,
    public refreshTrigger: Subject<
      FreshnessType
    > = new BehaviorSubject<FreshnessType>(
      getCurrentFreshness(),
    ),
  ) {
    // makeAutoObservable(this, {
    //   apiClient: false,
    //   tokenRepo: false,
    //   refreshTrigger: false,
    // });
    // refreshTrigger.next(getCurrentFreshness());
    makeObservable(this, {
      iotDevicesInner: true,
      iotDevices: true,
      apiCall$Sync: true,
      triggerListRefresh: true,
      deleteDevice: true,
    });
  }

  apiCall$Sync = refreshingAPICall$(
    this
      .apiClient
      .iot
      .getIotDevicesList
      .query,
  );

  get iotDevicesInner() {
    const userToken$Mobx = this
      .tokenRepo
      .userToken;
    if (userToken$Mobx.status !== 'success') {
      return userToken$Mobx;
    }
    const decodedToken = extractTokenData(
      userToken$Mobx.value,
    );
    const aa = expr(() => createAutoSyncedCaller3(
      this
        .apiClient
        .iot
        .getIotDevicesList
        .query,
      {
        userId: decodedToken.userId,
        userToken: userToken$Mobx.value,
      },
      this.refreshTrigger,
    ));
    return asSuccessPromise(aa);
  }

  get iotDevices() {
    if (this.iotDevicesInner.status !== 'success') {
      return this.iotDevicesInner;
    }
    const aa = this
      .iotDevicesInner
      .value
      .current()
      .value
      .value;
    return aa;
  }

  triggerListRefresh(
    freshness = getCurrentFreshness(),
  ) {
    this.refreshTrigger.next(freshness);
  }

  async deleteDevice(deviceId: IotDeviceIdT) {
    const userToken$Mobx = this
      .tokenRepo
      .userTokenWithData;
    if (userToken$Mobx.status !== 'success') {
      return userToken$Mobx;
    }
    const deletedDevice = await this
      .apiClient
      .iot
      .deleteIotDevice
      .mutate({
        deviceId,
        userToken: userToken$Mobx.value.token,
      });
    this.triggerListRefresh();
    return asSuccessPromise(deletedDevice);
  }
}
